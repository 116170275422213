.statement-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: -.8rem -1.5rem;
}

.statement-card {
  padding: 2.4rem;
  background-color: rgba($white, .1);
  border-radius: .8rem;
  margin: .8rem 1.5rem;
  width: 100%;

  h3 {
    font-size: 1.8rem;
    color: rgba($white, .6);
    margin-bottom: 1.6rem;
  }

  span {
    font-size: 2rem;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .statement-card {
    padding: 3.2rem;
    width: calc((100% - 6rem) / 2);

    h3 {
      font-size: 2.4rem;
    }

    span {
      font-size: 2.4rem;
    }
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .statement-card {
    width: calc((100% - 9rem) / 3);
  }
}
