*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100vh;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $navy;
  background-image:
    url("/dist/images/graphics/monogram-background.svg?v=270923"),
    url("/dist/images/graphics/grad1.svg"),
    url("/dist/images/graphics/grad2.svg"),
    url("/dist/images/graphics/grad3.svg");
  background-position:
    top right,
    top left,
    center right,
    bottom left;
  background-repeat: no-repeat;
  background-size: 75vw auto;
}

main {
  flex-grow: 1;
}

.main-section {
  padding-top: 8rem;
}

iframe,
img,
video,
svg {
  max-width: 100%;
}

img {
  height: auto;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: map-get($container__max-widths, lg);
  padding-left: 3rem;
  padding-right: 3rem;
}

.section {
  padding: 4rem 0;
}

.section--no-top-padding {
  padding-top: 0;
}

.section--no-bottom-padding {
  padding-bottom: 0;
}

.matrix-blocks {
  padding: 4rem 0 10rem;
}

.matrix-block--text {
  margin-bottom: 3rem;
}

.matrix-block--quote-with-image,
.matrix-block--image-with-caption,
.matrix-block--statement-cards,
.matrix-block--call-to-action {
  margin-top: 6rem;
  margin-bottom: 10rem;
}

.fb {
  display: flex;

  &--fd-col {
    flex-direction: column;
  }

  &--ai-start {
    align-items: flex-start;
  }

  &--ai-center {
    align-items: center;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  gap: 3rem 1.6rem;

  &.grid--no-gap {
    gap: 0;
  }
}

ul.grid,
.ul--reset {
  margin: 0;
  list-style: none;
  padding: 0;
}

.grid--ai-end {
  align-items: end;
}

.grid--ji-end {
  justify-items: end;
}

.col--display-sm,
.col--display-md,
.col--display-lg,
.col--display-xl,
.col--display-xxl {
  display: none;
}

.mt--0 {
  margin-top: 0;
}

.mb--0 {
  margin-bottom: 0;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    grid-column: span $i;
  }

  .mt--#{$i} {
    margin-top: .8rem * $i;
  }

  .mb--#{$i} {
    margin-bottom: .8rem * $i;
  }
}

@media (min-width: map-get($breakpoints, sm)) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      grid-column: span $i;
    }
  }

  .col--display-sm {
    display: block;
  }
}

@media (max-width: map-get($breakpoints, md) + 1) {
  .container--no-padding-md {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .grid {
    gap: 3rem;
  }

  .grid--min-md {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    &:not(.grid--no-gap) {
      gap: 3rem;
    }
  }

  .splide.is-initialized:not(.is-active) .splide__list.grid--min-md {
    display: grid;
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      grid-column: span $i;
    }
  }

  .col--display-md {
    display: block;
  }

  .section:not(.section--no-top-padding) {
    padding-top: 6rem;
  }

  .section:not(.section--no-bottom-padding) {
    padding-bottom: 6rem;
  }

  .main-section {
    padding-top: 14rem;
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      grid-column: span $i;
    }
  }

  .col--display-lg {
    display: block;
  }

  .section:not(.section--no-top-padding) {
    padding-top: 10rem;
  }

  .section:not(.section--no-bottom-padding) {
    padding-bottom: 10rem;
  }
}

@media (min-width: map-get($breakpoints, xl)) {
  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      grid-column: span $i;
    }
  }

  .col--display-xl {
    display: block;
  }
}

@media (min-width: map-get($breakpoints, xxl)) {
  @for $i from 1 through 12 {
    .col-xxl-#{$i} {
      grid-column: span $i;
    }
  }

  .col--display-xxl {
    display: block;
  }
}
