.tag-groups {
  display: flex;
  flex-wrap: wrap;
  margin: -1.2rem;
  margin-bottom: 1.2rem;
}

.tag-group {
  margin: 1.2rem;

  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  margin: -.2rem;
}

.tags-list--vertical {
  flex-direction: column;
  align-items: flex-start;
}

.tag {
  margin: .2rem;
  font-size: 1.6rem;
  padding: .4rem 1rem;
  border-left: 2px solid currentColor;
}

.tag--light {
  background-color: rgba($white, .1);
}

.tag--dark {
  background-color: rgba($black, .1);
}

.tags-homepage {
  background-image: url("/dist/images/graphics/africa.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left -4% center;
}

.tags-homepage__tag-group {
  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }

  h3 {
    color: rgba($white, .4);
    font-size: 2.4rem;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .tags-list--large {
    margin: -.4rem;
  }

  .tag--large {
    padding: .8rem 1.6rem;
    font-size: 2.4rem;
    margin: .4rem;
  }
}
