.call-to-action__wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 560px;
  }

  h2 {
    margin-top: 3.2rem;
    margin-bottom: 0;
    font-size: 3.2rem;
  }

  .button {
    margin-top: 2rem;
  }
}
