.frame {
  position: relative;

  img {
    display: block;
    position: relative;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
  }
}

.frame--1 {
  &:before {
    width: 20%;
    aspect-ratio: 1/1;
    top: -1.6rem;
    right: -1.6rem;
    background-image: url("/dist/images/graphics/frame-corner--white.svg");
    z-index: 1;
  }

  &:after {
    width: 80%;
    aspect-ratio: 10/1;
    bottom: -1.6rem;
    right: -1.6rem;
    background-color: rgba($white, .6);
    z-index: -1;
  }
}
