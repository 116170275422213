html {
  font-size: 62.5%;
}

body {
  color: $white;
  font-size: 1.6rem;
  line-height: 1.4;
  font-family: "Jost", sans-serif;
  box-shadow: inset 0 0 0 1rem rgba($white, 5%);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  // margin-top: 0;
}

@mixin bebas-neue() {
  font-family: "Bebas Neue", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: .8;
}

.bebas-neue {
  @include bebas-neue();
}

h1 {
  @include bebas-neue();
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-size: 3.649rem;
}

.super-h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 9rem;
  margin-bottom: 0;

  span {
    display: block;
  }

  strong {
    font-weight: normal;
    color: $sand;
  }
}

h2 {
  @include bebas-neue();
  font-size: 2.563rem * 1.25;
}

.super-h2 {
  font-size: 4rem;

  strong {
    @extend .highlight--red;
  }
}

h3 {
  font-size: 2.278rem;
}

h4 {
  font-size: 2.025rem;
}

h5 {
  font-size: 1.8rem;
}

.p-larger {
  font-size: 1.8rem;
}

p {
  margin-bottom: 1.6rem;
}

hr {
  box-sizing: border-box;
}

.lead-in-divider {
  padding: 0;
  margin: 0;
  width: 50px;
  height: 6px;
  border: 0;
  margin-bottom: 1.6rem;
}

.lead-in-divider--white {
  background-color: rgba($white, .3);
}

.lead-in-divider--light-grey {
  background-color: rgba($black, .3);
}

.lead-in-divider--black {
  background-color: rgba($black, .8);
}

.highlight--sand {
  color: $sand;
}

.highlight--teal {
  color: $teal;
}

.highlight--red {
  color: $red;
}

@mixin text-underline() {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: .5rem;
}

a {
  color: inherit;
  @include text-underline;

  &:hover {
    text-decoration-thickness: 2px;
  }
}

.quote__quote {
  > p:first-child:before,
  > p:last-child:after {
    content: "";
    width: 2.1rem;
    height: 1.7rem;
    display: inline-block;
    background: url("/dist/images/graphics/quote-mark.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  > p:first-child:before {
    margin-right: .6rem;
  }

  > p:last-child:after {
    margin-left: .6rem;
    transform: scaleX(-1) translateY(.5rem);
    background-position: top right;
  }

  &.quote__quote--dark {
    > p:first-child:before,
    > p:last-child:after {
      background: url("/dist/images/graphics/quote-mark--dark.svg");
    }
  }
}

.quote__name {
  font-size: 2.4rem;
  color: rgba($white, .8);
  display: block;
}

.quote__title {
  font-size: 1.8rem;
  color: rgba($white, .6);
  display: block;
}


@media screen and (min-width: map-get($breakpoints, md)) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 6.45rem;
  }

  .super-h1 {
    font-size: 10rem;
  }

  h2 {
    font-size: 3.732rem * 1.25;
  }

  h3 {
    font-size: 3.11rem;
  }

  h4 {
    font-size: 2.592rem;
  }

  h5 {
    font-size: 2.16rem;
  }

  p.larger {
    font-size: 2.16rem;
  }

  .quote__quote {
    p {
      position: relative;
    }

    > p:first-child:before,
    > p:last-child:after {
      position: absolute;
    }

    > p:first-child:before {
      transform: translateX(calc(-100% - .8rem)) translateY(.5rem);
    }
  }
}


@media screen and (min-width: map-get($breakpoints, lg)) {
  body {
    font-size: 2rem;
  }

  h1 {
    font-size: 8.583rem;
  }

  h2 {
    font-size: 4.883rem * 1.25;
  }

  .super-h2 {
    font-size: 9.6rem;
  }

  h3 {
    font-size: 3.906rem;
  }

  h4 {
    font-size: 3.125rem;
  }

  h5 {
    font-size: 2.5rem;
  }

  .p-larger {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .super-h1 {
    font-size: 14rem;
  }
}
