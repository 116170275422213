.social-links {
  display: flex;
  flex-wrap: wrap;
  margin: -.4rem;

  li {
    margin: .4rem;
  }

  a {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    border: 1px solid rgba($black, .5);
    transition: all $transition;

    &:hover {
      background-color: $sand;
      border-color: $sand;
      color: $black;
    }
  }

  svg {
    display: block;
  }
}
