.site-header {
  padding: 2.4rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all $transition--slow;
  border-color: darken($navy, 2%);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.site-header--stuck {
  background-color: $navy;
  box-shadow: 0 .8rem .8rem rgba($black, .2);
  border-bottom: 1px solid darken($navy, 2%);
  padding: 2rem 0;

  .site-logo img {
    height: 30px;
  }
}

.site-logo {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 2rem;

  img {
    display: block;
    width: auto;
    height: 40px;
    transition: height $transition;
  }
}

.primary-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.primary-nav__monogram {
  margin-bottom: 3rem;
  svg {
    display: block;
  }
}

button.mobile-menu-toggle {
  border: none;
  padding: 0;
  z-index: 1;

  svg {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  svg:nth-child(2) {
    display: none
  }

  &:hover {
    background-color: transparent;
    color: $white;
  }

  &--open {
    svg {
      display: none;
    }

    svg:nth-child(2) {
      display: block;
    }
  }
}

@media (max-width: map-get($breakpoints, lg)) {
  .primary-nav {
    display: none;
    font-size: 2.4rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
  }

  .primary-nav--open {
    display: flex;
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .site-header {
    padding: 4rem 0;
  }

  .site-header--stuck {
    padding: 2rem 0;
  }

  button.mobile-menu-toggle {
    display: none;
  }

  .primary-nav {
    ul {
      flex-direction: row;
      align-items: flex-end;
    }

    li:not(:last-child) {
      margin-right: 3.2rem;
    }

    a {
      color: rgba($white, .8);

      &:hover {
        color: $white;
        @include text-underline();
      }
    }
  }

  .primary-nav__monogram {
    display: none;
  }

  .site-logo img {
    height: 60px;
  }
}
