.button,
button {
  display: inline-flex;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
  border: 0;
  padding: 0;
  border: 1px solid $white;
  color: $white;
  padding: .8rem 2.4rem;
  text-align: center;
  @include bebas-neue();
  line-height: 1.4;
  transition: all $transition;

  &:hover {
    background-color: $sand;
    border-color: $sand;
    color: $black;
  }
}

.button--sand {
  background-color: $sand;
  border-color: $sand;
  color: $black;

  &:hover {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}
