.matrix-block--title-statement-with-copy {
  .super-h2 {
    margin: 0;
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .multi-columned__text {
    column-count: 2;
    column-gap: 3rem;
    margin-top: 3rem;

    p {
      break-inside: avoid-column;
    }
  }
}

@media (min-width: map-get($breakpoints, xl)) {
  .multi-columned__text {
    column-gap: 4rem;
  }
}
