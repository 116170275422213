.company-tile {
  border-right: 1px solid rgba($white, .2);

  &:last-child {
    border-bottom: none;
  }
}

.company-tile__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}

.company-tile__logo {
  max-height: 80px;
  max-width: 60%;
}

div.company-tile__link {
  .company-tile__logo {
    opacity: .4;
  }

  &:hover .company-tile__logo {
    opacity: 1;
  }
}

a.company-tile__link:hover .company-tile__logo {
  transform: scale(1.05);
}

.company-tile__logo {
  display: block;
  height: auto;
  width: auto;
  transition: $transition;
}

@media (min-width: map-get($breakpoints, md)) {
  .company-tile {
    border-bottom: 1px solid rgba($white, .2);
  }
}

@media (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1) {
  .company-tile {
    &:nth-last-child(2) {
      border-bottom: none;
    }

    &:nth-child(3n) {
      border-right: none;
    }
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .company-tile {
    &:nth-last-child(-n+4) {
      border-bottom: none;
    }

    &:nth-child(4n) {
      border-right: none;
    }
  }
}
