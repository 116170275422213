.person-homepage {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.person-homepage__container {
  width: 100%;
  max-width: calc( ((100vw - map-get($container__max-widths, lg)) / 2 ) + map-get($container__max-widths, lg) );
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.person-homepage__title {
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 3rem;
}

.people-tiles--homepage {
  width: 100%;
}

.person-tile__link {
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;

  &:hover {
    .person-tile__headshot {
      filter: grayscale(0);
    }

    .person-tile__name {
      text-decoration: underline;
    }
  }
}

.person-tile__headshot {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  flex-grow: 0;
  filter: grayscale(1);
  transition: filter $transition--slow;
}

.person-tile__name {
  font-size: 2.4rem;
}

.person-tile__title {
  font-size: 1.8rem;
  color: rgba($white, .6);
}

@media (min-width: map-get($breakpoints, md)) {
  .people-tiles--homepage {
    mask-image: linear-gradient(to right, black, black 65%, transparent);
  }

  .person-homepage__container {
    flex-direction: row;
    padding-left: 2rem;
  }

  .person-homepage__title {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
  }
}
