.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.modal__container {
  position: relative;
  background-color: #fff;
  color: $black;
  width: 100%;
  max-width: calc(100vw - 6rem);
  max-height: calc(100vh - 6rem);
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__body {
  padding: 3rem 8rem 5rem 3rem;
}

.modal__secondary,
.modal__gallery {
  padding: 3rem 3rem 5rem 3rem;
}

.modal__footer {
  padding: 3rem;
  background-color: $sand;
}

.modal__close {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  padding: 0;

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
    pointer-events: none;

    path {
      fill: $teal;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: transparent;

    svg path {
      fill: $sand;
    }
  }
}

.modal--person__title {
  color: rgba($black, .5);
  display: block;
  font-size: 2.4rem;
}

.modal--person__bio,
.modal--company__description {
  p:first-child {
    @extend .p-larger;
  }

  p:not(:first-child) {
    max-width: 560px;
  }
}

.modal--company__logo,
.modal--company__founder-headshot {
  width: 100%;
  height: auto;
  max-width: 240px;
}

.modal__secondary {
  background-color: $light-grey;
}

.modal__gallery {
  background-color: $teal;
  color: $white;
}

.company-gallery img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: map-get($breakpoints, lg)) {
  .modal__body,
  .modal__secondary,
  .modal__gallery {
    padding: 5rem 7.5rem;

    > .grid {
      gap: 5rem;
    }
  }
}

@media (min-width: calc(map-get($container__max-widths, lg) + 6rem)) {
  .modal__container {
    max-width: calc(map-get($container__max-widths, lg) - 6rem);
  }
}
