.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 2.4rem;
    transition: filter $transition--slow;
  }

  a {
    color: inherit;
    text-decoration: none;

    > :last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    @extend h4;
    margin-top: 0;
    margin-bottom: 1.2rem;
  }

  &:hover {
    img {
      filter: grayscale(1);
    }

    h2 {
      text-decoration: underline;
    }
  }

  p {
    font-size: 1.8rem;
  }
}

.card__date {
  display: block;
  color: rgba($white, .5);
}
