.post-meta {
  display: flex;
  align-items: center;
  margin-top: -2.4rem;
  margin-bottom: 4.8rem;
}

.post-meta__headshot {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1.6rem;

  img {
    display: block;
    border-radius: 100%;
  }
}

.post-meta__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.post-meta__author {
  display: block;
  margin-bottom: .8rem;
  font-size: 2.8rem;
}

.post-meta__date {
  color: rgba($white, .5);
}
