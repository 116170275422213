.fc--ntm > *:first-child {
  margin-top: 0;
}

.lc--nbm > *:last-child {
  margin-bottom: 0;
}

// https://scrollrevealjs.org/guide/user-experience.html
html.sr {
  h1,
  .standfirst,
  .super-h2,
  .company-tile__link,
  .team-tile__link,
  .tag {
    visibility: hidden;
  }
}
