.site-footer {
  margin-top: 2.4rem;
  font-size: 1.6rem;
  padding-bottom: 2.4rem;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.site-footer__border {
  width: 100%;
  height: 1px;
  background-color: rgba($white, .2);
  flex-shrink: 0;
  margin-bottom: 2.4rem;
}

.site-footer__nav-and-social {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
}

.site-footer__nav {
  margin-bottom: 2.4rem;
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  li {
    padding: .4rem;
  }

  a {
    color: inherit;
  }
}

.site-footer .social-links {
  justify-content: center;
}

.site-footer__monogram-and-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
    width: 24px;
    height: auto;
    margin-bottom: 1.6rem;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .site-footer .container {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .site-footer__nav-and-social {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }

  .site-footer__nav {
    margin-bottom: 0;

    ul {
      flex-direction: row;
    }

    li {
      padding: 0 1.6rem 0 0;
    }
  }

  .site-footer__monogram-and-copyright {
    flex-direction: row;

    img {
      margin-bottom: 0;
      margin-right: 1.6rem;
    }
  }
}
