table {
  width: 100%;
  border-spacing: 0;
}

th {
  text-align: left;
  text-transform: uppercase;
  vertical-align: bottom;
  border-bottom: 2px solid rgba($white, .8);
}

td, th {
  padding: 1rem 1rem 1rem 0;
}

td {
  border-bottom: 1px solid rgba($white, .2);
  vertical-align: top;
}
