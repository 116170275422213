.founder-profile {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.8rem;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    max-width: 460px;
    aspect-ratio: 9/1;
    opacity: .2;
    z-index: 2;
    mix-blend-mode: color-burn;
  }
}

.founder-profile__headshot {
  position: relative;

  img {
    display: block;
    width: 100%;
    z-index: 1;
    position: relative;
  }
}

.founder-profile__headshot-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 86%;
  opacity: .2;
  pointer-events: none;
  z-index: -1;
}

.founder-profile__headshot-square {
  position: absolute;
  bottom: -1.6rem;
  width: 18%;
  aspect-ratio: 1/1;
  pointer-events: none;
  z-index: -1;
}

.founder-profile__contents {
  display: flex;
  flex-direction: column;
}

.founder-profile__logo {
  display: block;
  width: auto;
  height: auto;
  max-width: 180px;
  margin-bottom: 2rem;
}

.founder-profile__copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3.6rem 2.4rem 2.4rem;
  background-color: rgba($white, .2);

  p, h2 {
    margin-top: 0;
  }

  h2 {
    margin-bottom: .3em;
  }

  .button {
    margin-top: 1.6rem;
  }
}

.founder-profile:nth-child(3n+1) {
  .founder-profile__headshot-bg {
    background-color: $teal;
  }

  .founder-profile__headshot-square {
    background-color: $sand;
  }
}

.founder-profile:nth-child(3n+2) {
  .founder-profile__headshot-bg {
    background-color: $sand;
  }

  .founder-profile__headshot-square {
    background-color: $teal;
  }
}

.founder-profile:nth-child(3n+3) {
  .founder-profile__headshot-bg {
    background-color: $red;
  }

  .founder-profile__headshot-square {
    background-color: $red;
  }
}

.founder-profile:nth-child(odd) {
  &:after {
    background: linear-gradient(270deg, $red 0%, rgba(192, 66, 66, 0.00) 100%);
  }

  .founder-profile__headshot-square {
    left: -1.6rem;
  }
}

.founder-profile:nth-child(even) {
  &:after {
    background: linear-gradient(90deg, $red 0%, rgba(192, 66, 66, 0.00) 100%);
    margin: 0;
    left: auto;
    right: 14%;
  }

  .founder-profile__headshot-square {
    right: -1.6rem;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .founder-profile {
    margin-bottom: 14rem;
  }

  .founder-profile:nth-child(odd) {
    flex-direction: row;

    .founder-profile__contents:before {
      content: "";
      flex-shrink: 0;
      flex-grow: 0;
      display: block;
      height: 14%;
    }

    .founder-profile__copy {
      padding-left: 13%;
      background: linear-gradient(270deg, rgba(255,255,255,.2) 0%, rgba(255,255,255,.1) 100%);

      &:before {
        background: linear-gradient(270deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,0) 100%);
        left: 0;
        transform: translateX(-100%);
      }
    }
  }

  .founder-profile:nth-child(even) {
    flex-direction: row-reverse;

    .founder-profile__copy {
      padding-right: 13%;
      padding-left: 3.6rem;
      background: linear-gradient(90deg, rgba(255,255,255,.2) 0%, rgba(255,255,255,.1) 100%);

      &:before {
        background: linear-gradient(90deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,0) 100%);
        // border-left: 1px solid #2B3D55;
        right: 0;
        transform: translateX(100%);
      }
    }
  }

  .founder-profile__headshot {
    width: 46.5%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .founder-profile__logo {
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 240px;
  }

  .founder-profile__copy {
    flex-grow: 1;
    padding: 4.2rem 3.6rem 3.6rem;
    margin-top: 3.6rem;
    position: relative;

    &:before {
      content: "";
      width: 20%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
}
