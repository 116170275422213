.expander {
  width: 100%;
}

button.expander__trigger {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  border: none;
  transition: all $transition;

  span {
    flex-grow: 1;
  }

  svg {
    display: block;
    flex-shrink: 0;
    transition: all $transition;
    opacity: .5;
  }

  &:hover {
    color: currentColor;
    cursor: pointer;

    svg {
      opacity: 1;
    }
  }
}

.expander__body {
  display: none;
}

.expander--faq {
  margin-bottom: 1.6rem;
  border-left: 4px solid $white;

  button.expander__trigger {
    padding: .8rem 2.4rem;
    background-color: rgba($white, .1);
    font-family: inherit;

    svg {
      margin-top: 1rem;
    }

    &:hover {
      background-color: rgba($white, .2);
    }
  }

  .expander__body {
    padding: 1.6rem 2.4rem;
    background-color: rgba($white, .05);
  }

  &.expander--expanded svg {
    transform: rotate(180deg);
  }
}

.expander--group {
  > .expander__heading button.expander__trigger {
    padding: .8rem 0;
    border-bottom: 1px solid rgba($white, .2);
    font-size: 2.563rem * 1.25;
    align-items: center;

    &:hover {
      background-color: transparent;
      @include text-underline();
    }
  }

  > .expander__body {
    padding: 2.4rem 0;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .expander--group {
    > .expander__heading button.expander__trigger {
      font-size: 3.732rem * 1.25;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .expander--group {
    > .expander__heading button.expander__trigger {
      font-size: 4.883rem * 1.25;
    }
  }
}
