//---------//
// Colours //
//---------//

$navy: #0E233E;
$sand: #F3AD5F;
$teal: #466C6A;
$dust: #A09484;
$black: #1A1A1A;
$white: #FFFFFF;
$light-grey: #F1EADF;
$red: #C04242;

$breakpoints: (
  sm: 480px,
  md: 640px,
  lg: 848px,
  xl: 1024px,
  xxl: 1400px
);

$container__max-widths: (
  lg: 1210px
);

$transition: 0.1s ease-out;
$transition--slow: 0.4s ease-out;
